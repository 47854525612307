


















import Vue from "vue";
import Component from "vue-class-component";
import { PurchaseGroupApi } from "@/api/PurchaseGroupApi";
import PurchaseGroupForm from "@/context/purchase_groups/components/PurchaseGroupForm.vue";
import { PurchaseGroup } from "@/context/purchase_groups/model/domain";
import { VForm } from "@/models/CustomTypes";
@Component({
  components: { PurchaseGroupForm }
})
export default class EditPurchaseGroupContainer extends Vue {
  purchaseGroup = new PurchaseGroup();
  async mounted() {
    this.purchaseGroup = await PurchaseGroupApi.findOne(parseInt(this.$route.params.id));
    console.log(this.purchaseGroup);
  }

  async submit() {
    const form = this.$refs.groupForm as VForm;
    if (form.validate()) {
      await PurchaseGroupApi.update(this.purchaseGroup, this.purchaseGroup.id);
      this.$router.push({ path: `/purchase_groups/show/${this.purchaseGroup.id}` });
    }
  }
}
