






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import { required } from "@/context/shared-kernel/validations";
@Component({
  components: { ClientAutocomplete }
})
export default class AddCustomerToPurchaseGroupForm extends Vue {
  rules = {
    client: [required]
  };
  client = null;
  valid = false;
  @Prop(Boolean) opened: boolean | undefined = false;

  emitValue(client) {
    this.$emit("client", client);
    this.clearForm();
    this.closeForm();
  }
  clearForm() {
    this.client = null;
  }
  closeForm() {
    this.$emit("close");
  }
}
