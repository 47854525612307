






















// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { IdNameModelType } from "@/models/CustomTypes";

type value = Record<string, unknown> | number;

@Component
export default class YearPicker extends Vue {
  @Prop({ default: "Rok" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: false }) hint: string;
  @Prop({ default: [] }) rules: [];
  @Prop({ default: false }) asString: boolean;
  @VModel({ type: [Number, Object] }) localValue: value;

  mounted() {
    if (!this.asString) {
      this.items = [
        {
          id: new Date().getFullYear(),
          name: new Date().getFullYear()
        },
        {
          id: new Date().getFullYear() + 1,
          name: new Date().getFullYear() + 1
        },
        {
          id: new Date().getFullYear() + 2,
          name: new Date().getFullYear() + 2
        }
      ];
    } else {
      this.items = [
        {
          id: new Date().getFullYear().toString(),
          name: new Date().getFullYear().toString()
        },
        {
          id: (new Date().getFullYear() + 1).toString(),
          name: (new Date().getFullYear() + 1).toString()
        },
        {
          id: (new Date().getFullYear() + 2).toString(),
          name: (new Date().getFullYear() + 2).toString()
        }
      ];
    }
  }

  items: { id: number | string; name: number | string }[];
}
