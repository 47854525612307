












































































import Vue from "vue";
import Component from "vue-class-component";
import {
  PurchaseGroup,
  PurchaseGroupMember,
  PurchaseGroupMembers
} from "@/context/purchase_groups/model/domain";
import { PurchaseGroupApi } from "@/api/PurchaseGroupApi";
import AddCustomerToPurchaseGroupForm from "@/context/purchase_groups/components/AddCustomerToPurchaseGroupForm.vue";
import { Nullable } from "@/models/CustomTypes";
@Component({
  components: { AddCustomerToPurchaseGroupForm }
})
export default class PurchaseGroupDetailsContainer extends Vue {
  purchaseGroup: Nullable<PurchaseGroupMembers> = new PurchaseGroupMembers();
  tabs = null;
  opened = false;
  async mounted(): Promise<void> {
    this.purchaseGroup = await PurchaseGroupApi.findOne(parseInt(this.$route.params.id));
    console.log(this.purchaseGroup);
  }
  openDialog() {
    this.opened = true;
  }
  async clientSelected(client) {
    let member = new PurchaseGroupMember(null, client.company);
    member = await PurchaseGroupApi.addMember(this.purchaseGroup.id, member);
    this.purchaseGroup.members.push(member);
  }
  deleteMember(memberToDelete) {
    console.log(memberToDelete);
    const idx = this.purchaseGroup.members.findIndex(m => m.name == memberToDelete.name);
    if (memberToDelete && memberToDelete.id) {
      PurchaseGroupApi.deleteMember(memberToDelete.id);
      this.purchaseGroup.members.splice(idx, 1);
    }
  }
}
