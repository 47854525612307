




























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import { required } from "@/context/shared-kernel/validations";
import YearPicker from "@/components/form-controls/YearPicker.vue";
import { VForm } from "@/models/CustomTypes";
import MediaTypeInput from "@/components/form-controls/MediaTypeInput.vue";
@Component({
  components: { MediaTypeInput, YearPicker, ClientAutocomplete }
})
export default class PurchaseGroupForm extends Vue {
  rules = {
    name: [required],
    year: [required]
  };
  @Prop({
    default: {
      name: null,
      year: null,
      mediaType: null,
      members: [],
      currentMember: null
    }
  })
  public form: {
    name: string;
    year: string;
    mediaType: string;
    members: { id: number; name: string }[];
    currentMember: { id: number; company: string };
  };
  tabs = null;
  valid = false;
  @Watch("form.currentMember", { immediate: true, deep: true })
  addMember() {
    if (this.form && this.form.currentMember) {
      this.form.members.push({
        id: this.form.currentMember.id,
        name: this.form.currentMember.company
      });
      this.form.currentMember = null;
    }
  }
  deleteMember(member) {
    this.form.members.splice(
      this.form.members.findIndex(m => m.name == member.name),
      1
    );
  }
  validate() {
    return (this.$refs.form as VForm).validate();
  }
}
